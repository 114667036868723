import React, { useMemo } from "react";
import "./Calendar.scss";
import range from "lodash/range";
import chunk from "lodash/chunk";
import moment from "moment";
import SunCalc from "suncalc";
import classNames from "classnames";
import { scaleLinear, scaleTime } from "d3-scale";
import { line, curveCatmullRom } from "d3-shape";

const moonScaleUp = scaleLinear().domain([0, 0.25, 0.5]).range([1, 0.5, -1]);

const moonScaleDown = scaleLinear()
  .domain([0.5, 0.75, 1.0])
  .range([1, 0.5, -1]);

const CALENDAR_WIDTH = 2100;
const CALENDAR_HEIGHT = 2970;
const CALENDAR_PADDING = 100;

const MONTH_WIDTH = (CALENDAR_WIDTH - CALENDAR_PADDING * 2) / 3;
const MONTH_HEIGHT = (CALENDAR_HEIGHT - CALENDAR_PADDING * 2) / 4;
const MONTH_PADDING = 10;
const DAY_WIDTH = (MONTH_WIDTH - MONTH_PADDING * 2) / 7;
const DAY_HEIGHT = (MONTH_HEIGHT - MONTH_PADDING * 2) / 6;

const MONTH_HEADER_HEIGHT = MONTH_HEIGHT / 10;

const DAY_PADDING = 5;

const dayScale = scaleLinear()
  .domain([0, 24 * 3600 * 1000])
  .range([0, DAY_WIDTH]);

const sunPathScale = scaleLinear()
  .domain([0, Math.PI / 2])
  .range([DAY_HEIGHT / 8, 0]);

const lineGenerator = line()
  .x(function (d) {
    return d.x;
  })
  .y(function (d) {
    return d.y;
  })
  .curve(curveCatmullRom.alpha(0.5));

const months = range(12).map((x) => moment({ month: x }));

const makeMonths = () => {
  return months.map((month) => {
    let day = moment({ month: month.month(), days: 1 });
    let days = [];
    while (day.month() === month.month()) {
      days.push(day.clone());
      day.add(1, "day");
    }
    return {
      month,
      days,
    };
  });
};

const Moon = ({ phase, radius }) => {
  const s = phase <= 0.5 ? moonScaleUp(phase) : moonScaleDown(phase);

  const flag1 = s > 0 ? "1" : "0";
  const flag2 = s > 0 ? "0" : "1";
  const p1 = Math.abs(s);
  const p1Path = `M 0,${-radius} A ${radius} ${radius} 180 1 1 0 ${radius} A ${
    p1 * radius
  } ${radius} 180 ${flag1} ${flag2} 0,${-radius}`;

  return (
    <g className="moon">
      {phase <= 0.5 && (
        <g>
          <circle r={radius}></circle>
          <path
            d={p1Path}
            style={{ strokeWidth: 0, stroke: "none", fill: "#fff" }}
          ></path>
        </g>
      )}
      {phase > 0.5 && (
        <g>
          <circle r={radius} className={classNames("moon-circle")}></circle>
          <path
            d={p1Path}
            style={{ strokeWidth: 0, stroke: "none", fill: "black" }}
          ></path>
        </g>
      )}
      <circle
        r={radius}
        style={{
          fill: "none",
          stroke: "rgba(255, 255, 255, 0.33)",
          strokeWidth: 0.5,
        }}
      ></circle>
    </g>
  );
};

const Day = ({ lat, lon, day }) => {
  const moonIllumination = SunCalc.getMoonIllumination(day.toDate());
  const moonTimes = SunCalc.getMoonTimes(day.clone().add(12, 'hours').toDate(), lat, lon);

  const sunTimes = SunCalc.getTimes(day.clone().add(12, 'hours').toDate(), lat, lon);

  const lightTime = moment
    .duration(
      sunTimes.sunset.getTime() - sunTimes.dawn.getTime(),
      "milliseconds"
    )
    .asMinutes();

  const lightPercent = lightTime / (60 * 24);

  const timesForPositions = range(0, 24, 1).map((h) =>
    day.clone().startOf("day").hours(h).toDate()
  );
  const sunPositions = timesForPositions.map((timeAndDate) => ({
    timeAndDate,
    positions: SunCalc.getPosition(timeAndDate, lat, lon),
  }));

  const baseTime = moment(day).startOf("day").toDate().getTime();
  const sunrisePosition = dayScale(sunTimes.sunrise.getTime() - baseTime);
  const sunsetPosition = dayScale(sunTimes.sunset.getTime() - baseTime);

  const sunPathData = sunPositions.map((pos) => ({
    x: dayScale(pos.timeAndDate.getTime() - baseTime),
    y: sunPathScale(pos.positions.altitude),
  }));

  const sunPath = lineGenerator(sunPathData);
  
  return (
    <g>
      <rect
        className="day-rect-fill"
        x={sunrisePosition}
        y={DAY_PADDING}
        width={sunsetPosition - sunrisePosition}
        height={DAY_HEIGHT - DAY_PADDING * 2}
      ></rect>
      <rect className="day-rect" width={DAY_WIDTH} height={DAY_HEIGHT}></rect>

      {/* <text className="day-annotation" x={DAY_WIDTH/2} y={50}>
    {lightPercent.toFixed(2)} %
  </text> */}

      <g transform={`translate(${DAY_WIDTH / 2}, ${DAY_HEIGHT / 8})`}>
        <Moon radius={6} phase={moonIllumination.phase}></Moon>
      </g>

      <g transform={`translate(0, ${DAY_HEIGHT / 3})`}>
        <path d={sunPath} className="sun-path"></path>
        <line
          x1={DAY_PADDING}
          x2={DAY_WIDTH - DAY_PADDING}
          y1={sunPathScale(0)}
          y2={sunPathScale(0)}
          className="sun-path-light"
        ></line>
        <line
          x1={DAY_PADDING}
          x2={DAY_WIDTH - DAY_PADDING}
          y1={sunPathScale(Math.PI / 2)}
          y2={sunPathScale(Math.PI / 2)}
          className="sun-path-light"
        ></line>
        <line
          x1={DAY_PADDING}
          x2={DAY_WIDTH - DAY_PADDING}
          y1={sunPathScale(-Math.PI / 2)}
          y2={sunPathScale(-Math.PI / 2)}
          className="sun-path-light"
        ></line>
      </g>

      <text x={DAY_WIDTH / 2} y={DAY_HEIGHT - DAY_PADDING * 2}>
        {/* <tspan className="day-label-neg" >{day.format('dd')} </tspan> */}
        <tspan className="day-label">{day.format("DD")} </tspan>
      </text>
    </g>
  );
};

const Month = ({ monthData, lat, lon }) => {
  return (
    <g>
      {/* <rect className="month-container light-stroke" width={MONTH_WIDTH} height={MONTH_HEIGHT}></rect> */}
      <text
        x={MONTH_WIDTH / 2}
        y={MONTH_HEADER_HEIGHT / 2}
        className="month-title"
      >
        {monthData.month.format("MMMM")}
      </text>
      {/* <line x1={MONTH_PADDING} y1={MONTH_HEADER_HEIGHT} y2={MONTH_HEADER_HEIGHT} x2={MONTH_WIDTH - MONTH_PADDING } className="light-stroke"></line> */}

      {/* days here */}
      <g transform={`translate(${MONTH_PADDING}, ${MONTH_HEADER_HEIGHT})`}>
        {monthData.days.map((day, k) => {
          const translateX = (day.weekday() % 7) * DAY_WIDTH;
          const translateY =
            parseInt((k + monthData.days[0].weekday()) / 7) * DAY_HEIGHT;
          return (
            <g key={k} transform={`translate(${translateX}, ${translateY})`}>
              <Day day={day} lat={lat} lon={lon} />
            </g>
          );
        })}
      </g>
    </g>
  );
};

export default function Calendar({ lat, lon }) {
  const allMonths = useMemo(() => {
    return makeMonths();
  }, []);

  const monthsChunks = chunk(allMonths, 3);

  return (
    <svg 
    // viewbox={`0 0 ${CALENDAR_WIDTH} ${CALENDAR_HEIGHT}`} 
    width={CALENDAR_WIDTH} height={CALENDAR_HEIGHT} className="Calendar">
      <g transform={`translate(${CALENDAR_PADDING}, ${CALENDAR_PADDING})`}>
        {monthsChunks.map((monthChunk, i) => (
          <g
            key={i}
            transform={`translate(${MONTH_PADDING}, ${
              MONTH_PADDING + MONTH_HEIGHT * i
            } )`}
          >
            {/* <rect className="month-row-container" width={CALENDAR_WIDTH - CALENDAR_PADDING * 2} height={MONTH_HEIGHT}>
      </rect> */}
            {monthChunk.map((monthData, j) => (
              <g key={j} transform={`translate(${MONTH_WIDTH * j}, 0)`}>
                <Month monthData={monthData} lat={lat} lon={lon}></Month>
              </g>
            ))}
          </g>
        ))}
      </g>
    </svg>
  );
}
