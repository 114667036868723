import React from 'react';
import Calendar from './components/Calendar'


const LAT = 45.69601
const LON = 9.66721

function App() {
  return (
    <Calendar lat={LAT} lon={LON}></Calendar>
  );
}

export default App;
